<script>
/* eslint-disable */
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Form-mask component
 */
export default {
  page: {
    title: 'Form Mask',
    meta: [{name: 'description', content: appConfig.description}],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'Form Mask',
      items: [
        {
          text: 'Forms',
          href: '/',
        },
        {
          text: 'Form Mask',
          active: true,
        },
      ],
      digit: '',
      zipcode: '',
      crazyzip: '',
      money: '',
      date: '',
      hour: '',
      datetime: '',
      ipaddress: '',
      cnpj: '',
      cpf: '',
      celphone: '',
      ustelephone: '',
      areacode: '',
      telephone: '',
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Example</h4>
            <form>
              <div class="row">
                <div class="col-lg-6">
                  <div>
                    <div>
                      <label class="form-label" for="regexp-mask">Dynamic mask</label>
                      <input id="regexp-mask" v-maska="['# cm', '#.# cm', '#.## cm']"
                             class="form-control" type="text">
                      <div class="text-muted">["# cm", "#.# cm", "#.## cm"]</div>
                    </div>

                    <div class="mt-4">
                      <label class="form-label" for="phone-mask">Phone with code</label>
                      <input id="phone-mask" v-maska="['+1 (###) ##-##-##', '+1 (###) ###-##-##']"
                             class="form-control" type="text">
                      <div class="text-muted">+1 (###) ###-####</div>
                    </div>
                    <div class="mt-4">
                      <label class="form-label" for="number-mask">Credit card</label>
                      <input id="number-mask" v-maska="'#### #### #### ####'" class="form-control"
                             type="text">
                      <div class="text-muted">#### #### #### ####</div>
                    </div>
                  </div>
                </div>
                <!-- end col -->

                <div class="col-lg-6">
                  <div class="mt-4 mt-lg-0">
                    <div>
                      <label class="form-label" for="date-mask">Date</label>
                      <input id="date-mask" v-maska="'##/##/####'" class="form-control" type="text">
                      <div class="text-muted">##/##/####</div>
                    </div>

                    <div class="mt-4">
                      <label class="form-label">Hex color</label>
                      <input id="dynamic-mask"
                             v-maska="{ mask: ['!#HHHHHH', '!#HHHHHH-HH'], tokens: { 'H': { pattern: /[0-9a-fA-F]/, uppercase: true }}}"
                             class="form-control" type="text">
                      <div class="text-muted">!#HHHHHH</div>
                    </div>
                    <div class="mt-4">
                      <label class="form-label">CPF/CNPJ</label>
                      <input id="currency-mask" v-maska="['###.###.###-##', '##.###.###/####-##']"
                             class="form-control" type="text">
                      <div class="text-muted">["###.###.###-##", "##.###.###/####-##"]</div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
              <!-- end row -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
